import React, { useContext, useEffect } from 'react';

import { Drawer, Form, Input, DatePicker, Button, message } from 'antd';
import moment from 'moment-timezone';
import { parse, Node } from 'node-html-parser';
import ReactDOMServer from 'react-dom/server';

import { GlobalContext } from '../context/GlobalContextProvider';
import { usePaper } from '../hooks';
import { handleSuccess } from '../utility';
import Page from './Page';
import TitlePage from './TitlePage';

export const TitlePageDrawer = ({
    visible,
    setVisibility,
}: {
    visible: boolean;
    setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [form] = Form.useForm();
    const context = useContext(GlobalContext);
    const { updateTitlePage } = usePaper();

    const closeModal = () => {
        setVisibility(false);
    };

    const finishTitlePageEdit = (values: AutoAPA.TitlePage) => {
        updateTitlePage(values);
        message.success('Title page has been updated');
        closeModal();
    };

    useEffect(() => {
        if (Object.keys(context.titlePage).length) {
            form.setFieldsValue({ ...context.titlePage, date: moment(context.titlePage.date) });
        }
    }, [context.titlePage]);

    return (
        <Drawer
            title="Title Page"
            placement="right"
            onClose={closeModal}
            visible={visible}
            width="25vw"
            footer={
                <Button type="primary" block onClick={() => form.submit()}>
                    Update Title Page
                </Button>
            }
        >
            <Form form={form} labelCol={{ span: 24 }} onFinish={finishTitlePageEdit}>
                <Form.Item name="title" label="Title">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="question" label="Question">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="author" label="Author">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="institution" label="Institution">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="courseNumberAndName" label="Course Name and Number">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="instructor" label="Instructor">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="date" label="Date">
                    <DatePicker style={{ width: '100%' }} allowClear />
                </Form.Item>
                <Form.Item name="runningHead" label="Running Head">
                    <Input allowClear />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default TitlePageDrawer;
