import React, { useContext, useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Drawer, Form, Input, Button, message, Typography, Radio, Upload } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import imageToBase64 from 'image-to-base64/browser';

import { GlobalContext } from '../context/GlobalContextProvider';

export const AddTableDrawer = ({
    visible,
    setVisibility,
}: {
    visible: boolean;
    setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [form] = Form.useForm();
    const context = useContext(GlobalContext);

    const closeModal = () => {
        setVisibility(false);
    };

    const insertTable = async () => {
        const formValues = form.getFieldsValue();
        const { rows, columns, title } = formValues;
        const notes = formValues.notes;

        const columnsHtml = `<td style="width: ${
            100 / columns
        }%;"><div class="table" style="max-width: 100%;">&nbsp;</div></td>`.repeat(columns);
        const rowsHtml = `<tr>${columnsHtml}</tr>`.repeat(rows);
        const notesRow = notes
            ? `<tr><td colspan="${columns}" class="col-span-2" colspan="2"><i>Note.</i> ${notes}</td></tr>`
            : '';
        const tableHtml = `<table border="1" style="border-collapse: collapse;table-layout:fixed; width: 100%;"><tbody>${rowsHtml}${notesRow}</tbody></table>`;
        context.editor.current?.insertContent(
            `<p class="no-indent table" data-insert="table"><strong id="replace-table-id">Table #</strong><br/><i>${formValues.title}</i>${tableHtml}</p>`,
        );
    };

    useEffect(() => {
        
        form.resetFields();
    }, []);

    return (
        <Drawer
            title="Insert Table"
            placement="right"
            onClose={closeModal}
            visible={visible}
            width="25vw"
            footer={
                <Button type="primary" block onClick={() => form.submit()}>
                    Insert Table
                </Button>
            }
            destroyOnClose={true}
        >
            <Typography.Paragraph>
                To create a table, place your cursor where you want the table to be placed. Then, enter the table&apos;s
                details below.
            </Typography.Paragraph>
            <Typography.Paragraph>
                Enter your table&apos;s details below, then click Insert Table. We will create and format your table for
                you.
            </Typography.Paragraph>

            <Form form={form} labelCol={{ span: 24 }} onFinish={insertTable}>
                <Form.Item
                    name="title"
                    label="Table Title"
                    extra="Titles should be concise and use Title Case capitalization."
                    rules={[{ required: true, message: 'Please input the title of the figure' }]}
                >
                    <Input allowClear />
                </Form.Item>
                <Form.Item
                    name="columns"
                    label="Columns"
                    extra="The number of columns needed, including Headings."
                    rules={[{ required: true, message: 'Please input the number of columns' }]}
                >
                    <Input allowClear type="number" />
                </Form.Item>
                <Form.Item
                    name="rows"
                    label="Rows"
                    extra="The number of rows needed, including Headings."
                    rules={[{ required: true, message: 'Please input the number of rows' }]}
                >
                    <Input allowClear type="number" />
                </Form.Item>
                <Form.Item
                    name="notes"
                    label="Table notes (optional)"
                    extra="Table notes are used to clarify information and avoid repetition in a table."
                >
                    <Input allowClear />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default AddTableDrawer;
