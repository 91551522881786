import React, { useContext } from 'react';

import { Drawer, Typography, Divider, Button, message } from 'antd';
import { parse, Node } from 'node-html-parser';
import ReactDOMServer from 'react-dom/server';

import { GlobalContext } from '../context/GlobalContextProvider';
import AbstractPage from './AbstractPage';
import Page from './Page';

export const AbstractPageDrawer = ({
    visible,
    setVisibility,
}: {
    visible: boolean;
    setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const context = useContext(GlobalContext);

    const closeModal = () => {
        setVisibility(false);
    };

    const removeAbstractPage = () => {
        const root = parse(context.html);
        const childNodes = root.childNodes[0].childNodes as (Node & {
            rawTagName?: string;
            classNames?: string[];
            id?: string;
        })[];
        // Replace abstract page
        root.childNodes[0].childNodes = childNodes.filter(childNode => {
            if (
                childNode.rawTagName === 'div' &&
                childNode.classNames?.includes('page') &&
                childNode.id === 'abstractPage'
            ) {
                return false;
            } else return true;
        });
        context.setHtml(root.innerHTML);
        message.success('Abstract page has been removed');
    };

    const addAbstractPage = () => {
        const root = parse(context.html);
        const abstractPage = ReactDOMServer.renderToString(
            <Page id="abstractPage">
                <AbstractPage />
            </Page>,
        );
        const abstractPageNode = parse(abstractPage);
        const parent = root.firstChild;
        const childNodes = parent.childNodes as (Node & {
            rawTagName?: string;
            classNames?: string[];
            id?: string;
        })[];
        // Add abstract page after title page
        let titlePageNodeIndex = -1;
        childNodes.forEach((childNode, i) => {
            if (
                childNode.rawTagName === 'div' &&
                childNode.classNames?.includes('page') &&
                childNode.id === 'titlePage'
            ) {
                titlePageNodeIndex = i;
            }
        });
        if (titlePageNodeIndex > -1) childNodes.splice(titlePageNodeIndex + 1, 0, ...abstractPageNode.childNodes);
        context.setHtml(root.innerHTML);
        message.success('Abstract page has been added');
    };

    return (
        <Drawer title="Abstract Page" placement="right" onClose={closeModal} visible={visible} width="25vw">
            <p>The abstract should give a succinct description of your paper in under 250 words.</p>
            <p>They are often followed by a keywords section.</p>
            {context.html.match(/id="abstractPage"/gi)?.length ? (
                <Button danger type="default" block onClick={removeAbstractPage}>
                    Remove Abstract Page
                </Button>
            ) : (
                <Button type="primary" block onClick={addAbstractPage}>
                    Add Abstract Page
                </Button>
            )}
            <Divider />
            <Typography.Text type="secondary">
                <p>
                    <strong>Writing tips for abstracts</strong>
                </p>
                <p>
                    Abstracts are a brief summary of your paper&apos;s content that lets the reader know what you are
                    going to discuss.
                </p>
                <p>It should always be a single paragraph that is 150 to 250 words long. </p>
                <p>
                    Good abstracts should be brief, clear, and non-biased (a report on the paper, not an opinion about
                    it).
                </p>
                <p>
                    You can add keywords to help your paper be more easily discovered by other researchers or students.{' '}
                </p>
            </Typography.Text>
        </Drawer>
    );
};

export default AbstractPageDrawer;
