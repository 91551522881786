import React, { SetStateAction, useContext, useEffect, useState } from 'react';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Drawer, Typography, Divider, Button, Form, Input, Space, Radio, Select } from 'antd';
import Styled from 'styled-components';

import { GlobalContext } from '../../context/GlobalContextProvider';
import { BookReferenceForm } from './';
import LookupForm from './LookupForm';

const ManualCategory = Styled.div`
    &:hover{
        background-color: #f0f0f0;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        padding: 1rem;
        transition: all 0.3s ease;
    }
`;

export const NewReference = ({ hideReferenceAdder }: { hideReferenceAdder: () => void }) => {
    const [showManualCategory, setShowManualCategory] = useState<string>();

    const manualReferenceCategories = [
        {
            id: 'book',
            title: 'Book',
            description:
                'This reference is for printed books, e-books, and audiobooks. You can also cite foreign language and republished books.',
        },
        {
            id: 'gray-literature',
            title: 'Gray Literature (Reports, Briefs, Codes)',
            description:
                'Gray Literature (Reports, Briefs, Codes) A flexible category of works published outside of peer-reviewed channels. It can include government reports, internal business documents, and topical briefs.',
        },
        {
            id: 'journal',
            title: 'Journal',
            description: `Journals are peer-reviewed periodicals in publication or review (e.g., UpToDate). You can cite an article, an article's abstract, an entire issue, or a journal supplement.`,
        },
        {
            id: 'webpage',
            title: 'Webpage',
            description: `This is for content that is only found online. It shouldn't be used for digital versions of print content (e.g., Journal Articles read on the web).`,
        },
    ];

    const ManualCategories = () => (
        <>
            <Typography.Title level={5}>Manually create a reference</Typography.Title>
            <Space direction="vertical">
                {manualReferenceCategories.map(category => (
                    <ManualCategory onClick={() => setShowManualCategory(category.id)} key={category.id}>
                        <Typography.Paragraph strong>{category.title}</Typography.Paragraph>
                        <Typography.Paragraph>{category.description}</Typography.Paragraph>
                    </ManualCategory>
                ))}
            </Space>
        </>
    );

    return (
        <>
            {!showManualCategory && (
                <>
                    <LookupForm />
                    {/* <Divider />
                    <ManualCategories /> */}
                </>
            )}
            {showManualCategory && <BookReferenceForm hideForm={hideReferenceAdder} category={showManualCategory} />}
        </>
    );
};

export default NewReference;
