import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Button, Radio, Pagination, Divider, Select, List, Typography } from 'antd';

import useReference from '../../hooks/useReferences';
import {
    googleScholarSearchKeyword,
    handleError,
    googleScholarCitationSearch,
    referenceToBibTex,
    apiRequester,
} from '../../utility';

const GoogleScholarLookup = ({ keyword, since }: { keyword: string; since?: string }) => {
    const [loading, setLoading] = useState(false);
    const [googleScholarResult, setGoogleScholarResult] = useState<APIResponse.GoogleScholarLookupResponse>();
    const [startIndex, setStartIndex] = useState(0);
    const { convertBibTexToCitation, addReference, convertBibTexToReference } = useReference();

    const lookup = async () => {
        try {
            setLoading(true);
            const result = await googleScholarSearchKeyword({
                keyword,
                start: startIndex,
                since: since !== 'any' ? since : undefined,
            });
            setGoogleScholarResult(result);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const addToReferences = async (resultId: string) => {
        try {
            setLoading(true);
            const result = await googleScholarCitationSearch({
                resultId,
            });

            let bibTex: string | undefined;

            try {
                // Try PaperPile API first
                const referenceObj = result.citations.find(c => c.title === 'APA');
                const plainReference = referenceObj?.snippet;
                const bibTexResponse = await referenceToBibTex(plainReference!);
                bibTex = bibTexResponse.output?.split('\n\n')[0]?.toString();
            } catch (err) {
                // If PaperPile API fails, try Google Scholar scraper
                const bibTexLink = result.links.find(l => l.name === 'BibTeX')?.link!;
                bibTex = await apiRequester.proxyUrl<string>(bibTexLink);
            }

            const reference = convertBibTexToReference(bibTex!);
            const inTextCitation = await convertBibTexToCitation(bibTex!);
            addReference({ rawCitation: inTextCitation, rawReference: reference, bibTex, source: 'google-scholar' });
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        keyword && lookup();
    }, [keyword, startIndex, since]);

    return (
        <>
            <List
                loading={loading}
                dataSource={googleScholarResult?.organic_results}
                renderItem={item => (
                    <List.Item
                        key={item.result_id}
                        extra={
                            <Button
                                type="link"
                                icon={<PlusOutlined />}
                                style={{ color: '#1a0dab' }}
                                onClick={() => addToReferences(item.result_id)}
                            >
                                Add Reference
                            </Button>
                        }
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                                <Typography.Link strong href={item.link} target="_blank" style={{ color: '#1a0dab' }}>
                                    {item.type ? `[${item.type.toUpperCase()}] ` : ''}
                                    {item.title}
                                </Typography.Link>{' '}
                                <div>
                                    {item.resources?.map(res => (
                                        <Typography.Link
                                            strong
                                            href={res.link}
                                            target="_blank"
                                            style={{ fontSize: '12px', color: '#1a0dab' }}
                                            key={res?.title}
                                        >
                                            [{res.file_format}] {res?.title}
                                        </Typography.Link>
                                    ))}{' '}
                                    <Typography.Text type="secondary" style={{ fontSize: '12px', color: 'green' }}>
                                        {item?.publication_info?.summary}
                                    </Typography.Text>
                                </div>
                            </div>

                            <div>{item.snippet}</div>
                            <div></div>
                        </div>
                    </List.Item>
                )}
            />
            <Pagination
                total={googleScholarResult?.search_information?.total_results}
                pageSize={20}
                current={googleScholarResult?.pagination?.current}
                showSizeChanger={false}
                showQuickJumper={false}
                responsive
                style={{ textAlign: 'center' }}
                onChange={page => {
                    setStartIndex((page - 1) * 20);
                }}
            />
        </>
    );
};

export const LookupForm = () => {
    const [referenceLookupForm] = Form.useForm();
    const [formState, setFormState] = useState<{ source: string; keyword: string; since: string }>();

    const lookupFinish = async (values: any) => {
        setFormState(values);
    };

    return (
        <div style={{ paddingRight: '1.5px', borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}>
            <Form form={referenceLookupForm} onFinish={lookupFinish} layout="inline">
                <Form.Item
                    name="keyword"
                    label="Keyword"
                    // extra="Search for journal articles and books using DOI, ISBN, or author &amp; title. Find webpages using a URL."
                    rules={[{ required: true, message: 'Please enter lookup string' }]}
                >
                    <Input placeholder="Search for journal, articles and books." />
                </Form.Item>
                <Form.Item
                    name="since"
                    label="Since"
                    rules={[{ required: true, message: 'Please select since' }]}
                    initialValue="2019"
                >
                    <Select>
                        <Select.Option value="2019">Since 2019</Select.Option>
                        <Select.Option value="any">Any time</Select.Option>
                        <Select.Option value="2023">Since 2023</Select.Option>
                        <Select.Option value="2022">Since 2022</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Search
                    </Button>
                </Form.Item>
            </Form>
            <Divider />
            {formState?.keyword && <GoogleScholarLookup keyword={formState?.keyword} since={formState?.since} />}
        </div>
    );
};

export default LookupForm;
