import React, { LegacyRef, useContext, useEffect, useRef, useState } from 'react';

import { Drawer, Typography, Divider, Button, message } from 'antd';
import { saveAs } from 'file-saver';
import HTMLtoDOCX from 'html-to-docx';
import html2canvas from 'html2canvas';
import toc from 'markdown-toc-unlazy';
// import { NodeHtmlMarkdown, NodeHtmlMarkdownOptions } from 'node-html-markdown';
import { parse, HTMLElement, Node } from 'node-html-parser';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { renderToStaticMarkup } from 'react-dom/server';

// https://www.npmjs.com/package/pdfreader

import { GlobalContext } from '../context/GlobalContextProvider';
import { getTocFromPdf, htmlToPdf } from '../utility';
import AbstractPage from './AbstractPage';
import Page from './Page';
import TableOfContentsPage from './TableOfContentsPage';

/* eslint-disable @typescript-eslint/no-var-requires */
const nodeHtmlMarkdown = require('node-html-markdown');

export const TableOfContentsPageDrawer = ({
    visible,
    setVisibility,
}: {
    visible: boolean;
    setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const context = useContext(GlobalContext);
    const [tocList, setTocList] = useState<{ text: string; level: number; page: number }[]>([]);
    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        setVisibility(false);
    };

    useEffect(() => {
        if (typeof window !== 'undefined') (window as any).html2canvas = html2canvas;
    }, []);

    const removeTableOfContentsPageDrawerPage = () => {
        const root = parse(context.html);
        const childNodes = root.childNodes[0].childNodes as (Node & {
            rawTagName?: string;
            classNames?: string[];
            id?: string;
        })[];
        // Replace table of contents page
        root.childNodes[0].childNodes = childNodes.filter(childNode => {
            if (
                childNode.rawTagName === 'div' &&
                childNode.classNames?.includes('page') &&
                childNode.id === 'tableOfContentsPage'
            ) {
                return false;
            } else return true;
        });
        context.setHtml(root.innerHTML);
        message.success('Table of contents page has been removed');
    };

    const addTableOfContentsPageDrawerPage = () => {
        const root = parse(context.html);
        const tableOfContentsPage = ReactDOMServer.renderToString(
            <Page id="tableOfContentsPage">
                <TableOfContentsPage />
            </Page>,
        );
        const tableOfContentsPageNode = parse(tableOfContentsPage);
        const parent = root.firstChild;
        const childNodes = parent.childNodes as (Node & {
            rawTagName?: string;
            classNames?: string[];
            id?: string;
        })[];
        // Add table of contents page after abstract page or after title page
        let titlePageNodeIndex = -1;
        childNodes.forEach((childNode, i) => {
            if (
                childNode.rawTagName === 'div' &&
                childNode.classNames?.includes('page') &&
                childNode.id === 'titlePage'
            ) {
                titlePageNodeIndex = i;
            }
        });
        if (titlePageNodeIndex > -1)
            childNodes.splice(titlePageNodeIndex + 1, 0, ...tableOfContentsPageNode.childNodes);
        context.setHtml(root.innerHTML);
        message.success('Table of contents page has been added');
    };

    const pxToMm = (px: number) => {
        const div = document.createElement('div');
        div.style.display = 'block';
        div.style.height = '100mm';
        document.body.appendChild(div);
        const mm = Math.floor(px / (parseFloat(window.getComputedStyle(div, null).height) / 100));
        div.parentNode!.removeChild(div);
        return mm;
    };

    const getPageStats = (pageId: string) => {
        const pagePreviewDiv = document.getElementById(pageId);
        if (!pagePreviewDiv) return { pageCount: 0, contentHeight: 0 };
        const px = parseFloat(window.getComputedStyle(pagePreviewDiv!, null).height);
        const contentHeightInMm = pxToMm(px);
        const singlePageHeight = 297 - 25.4 - 25.4;
        const pageCount = Math.ceil(contentHeightInMm / singlePageHeight);
        return { pageCount, contentHeight: contentHeightInMm };
    };

    const getTitles = (): { content: string; slug: string; lvl: number; i: number }[] => {
        const contentPage = document.getElementById('contentPage')!.innerHTML;
        const tocMarkdown = nodeHtmlMarkdown.NodeHtmlMarkdown.translate(
            contentPage!,
            { blockElements: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] },
            undefined,
            undefined,
        );
        const titleMarkdowns = tocMarkdown
            .split('\n')
            .filter((x: any) => x[0] === '#')
            .join('\n');
        const titles = toc(titleMarkdowns).json;
        // const contentPageHeight = getPageStats('contentPagePreview');
        // const singlePageHeight = 297 - 25.4 - 25.4;
        // titles.map((title: any) => {
        //     const level = title.lvl;
        //     const previewNode = document.getElementById(`contentPagePreview`);
        //     const titleNodes = document.getElementsByTagName(`h${level}`) as unknown as HTMLElement[];
        //     const node = Array.from(titleNodes).find(node => node.innerHTML === title.content);
        //     const previewNodeTopOffset = pxToMm(previewNode!.offsetTop);
        //     const titleTopOffset = pxToMm(node!.offsetTop);
        //     const titleTopOffsetFromPreviewNode = titleTopOffset - previewNodeTopOffset;
        //     const pageNumber = Math.ceil(titleTopOffsetFromPreviewNode / singlePageHeight);
        //     title.pageNumber = pageNumber;
        // });
        return titles;
    };

    const getPage = (root: HTMLElement, selector: string) => {
        const pageNode = root.querySelectorAll(selector);
        const htmlOfNodes = pageNode.length > 0 ? pageNode.map(node => node.innerHTML) : [];
        const completePageNode = htmlOfNodes.map(html =>
            parse(
                renderToStaticMarkup(
                    <div className="page" style={{ pageBreakAfter: 'always' }}>
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                    </div>,
                ),
            ),
        );
        return completePageNode;
    };

    const getCleanHtml = (dirtyHtml: string, target?: string) => {
        if (dirtyHtml) {
            const root = parse(dirtyHtml);
            const newRoot = parse(
                `<div style='font-family: "Times New Roman", Times, serif; font-size: 12px; color: black;'></div>`,
            );
            const completeTitlePageNodes = getPage(root, '.titlePage');
            const completeAbstractPageNodes = getPage(root, '.abstractPage');
            const tableOfContentsPage = getPage(root, '.tableOfContentsPage');
            const completeContentPageNodes = getPage(root, '.contentPage');
            const completeReferencePageNodes = getPage(root, '.referencePage');
            const completeConclusionPageNodes = getPage(root, '.conclusionPage');
            const completeAppendixPageNodes = getPage(root, '.appendixPage');

            newRoot.childNodes[0].childNodes.push(
                ...completeTitlePageNodes,
                ...completeAbstractPageNodes,
                ...tableOfContentsPage,
                ...completeContentPageNodes,
                ...completeConclusionPageNodes,
                ...completeReferencePageNodes,
                ...completeAppendixPageNodes,
            );

            // Force add line height to all elements
            const paragraphElements = root.querySelectorAll('*');
            paragraphElements.map(element => {
                const style = element.getAttribute('style');
                element.setAttribute('style', `line-height: 2; ${style}`);
            });
            const returnHtml = `<html><head>
           
            </head><body>${newRoot.innerHTML}</body></html>`;
            return returnHtml;
        } else return '';
    };

    const getToc = async () => {
        const abstractPageCount = getPageStats('abstractPagePreview');
        const tableOfContentsPage = getPageStats('tableOfContentsPagePreview');
        const contentPageCount = getPageStats('contentPagePreview');
        const referencePageCount = getPageStats('referencePagePreview');
        const appendixPageIds = [
            'appendixPage-A',
            'appendixPage-B',
            'appendixPage-C',
            'appendixPage-D',
            'appendixPage-E',
            'appendixPage-F',
            'appendixPage-G',
            'appendixPage-H',
            'appendixPage-I',
            'appendixPage-J',
        ];
        const appendixPages = appendixPageIds.map(pageId => getPageStats(`${pageId}Preview`));
        console.log({ abstractPageCount, tableOfContentsPage, contentPageCount, referencePageCount, appendixPages });
        const titles = getTitles();
        const titleObject: { [slug: string]: { text: string; level: number; page: number } } = {};
        const slugs: string[] = [];
        titles.splice(0, 0, {
            content: 'Auto APA Formatting',
            i: 0,
            lvl: 1,
            slug: 'cover',
        });
        titles.push({
            content: 'Abstract',
            i: 0,
            lvl: 1,
            slug: 'abstract',
        });
        titles.push({
            content: 'References',
            i: 0,
            lvl: 1,
            slug: 'references',
        });
        titles.push({
            content: 'Conclusion',
            i: 0,
            lvl: 1,
            slug: 'conclusion',
        });
        [
            'Appendix A',
            'Appendix B',
            'Appendix C',
            'Appendix D',
            'Appendix E',
            'Appendix F',
            'Appendix G',
            'Appendix H',
            'Appendix I',
            'Appendix J',
        ].map(appendixPageId => {
            titles.push({
                content: appendixPageId,
                i: 0,
                lvl: 1,
                slug: appendixPageId.toLowerCase().replace(' ', '-'),
            });
        });
        titles.map(title => {
            const slug = title.slug;
            const content = title.content;
            titleObject[slug] = { text: content, level: title.lvl, page: 0 };
            slugs.push(slug);
        });

        const html = context.html;
        const cleanHtml = getCleanHtml(html);
        const pdf = await htmlToPdf({
            htmlString: cleanHtml,
            runningHeadTitle: context.titlePage.runningHead || '',
        });
        const toc = await getTocFromPdf({ pdf, titles: titleObject });

        // // Increment page number due to toc
        // slugs.map(slug => {
        //     if (toc[slug]) {
        //         if (toc[slug].page) toc[slug].page += 1;
        //     }
        // });
        // Sort by page number
        const sortedToc = [];
        for (const slug in toc) {
            sortedToc.push([toc[slug].page, toc[slug]]);
        }
        sortedToc.sort((a, b) => a[0] - b[0]);

        return sortedToc.map(item => item[1]);
    };

    const refreshTableOfContents = async () => {
        setLoading(true);
        const toc = await getToc();
        setTocList(toc);
        setLoading(false);
    };

    const getPagePreview = (pageId: string) => {
        const root = parse(context.html);
        const pageElement = root.querySelector(`#${pageId}`);
        if (!pageElement) return;
        const pageRawHtml = pageElement?.innerHTML!;
        const formattedHtml = `<div id="${pageId}Preview" style='visibility: hidden; font-family: "Times New Roman", Times, serif; font-size: 12pt; color: black; line-height: 2; width: 210mm; padding-left: 25.4mm; padding-right: 25.4mm;'>${pageRawHtml}</div>`;
        return <div dangerouslySetInnerHTML={{ __html: formattedHtml }} />;
    };

    // const xToPx = (x: string) => {
    //     var div = document.createElement('div');
    //     div.style.display = 'block';
    //     div.style.height = x;
    //     document.body.appendChild(div);
    //     var px = parseFloat(window.getComputedStyle(div, null).height);
    //     div.parentNode!.removeChild(div);
    //     return px;
    // };

    useEffect(() => {
        const filteredTocList = tocList.filter(item => item.page);
        const TocTable = (
            <tbody>
                {filteredTocList.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td
                                style={{ width: '100%', paddingLeft: `${20 * (item.level - 1)}px` }}
                                className={`indent-left-${item.level}`}
                            >
                                {item.text}
                            </td>
                            <td
                                style={{ width: '100%', fontSize: '12px', textAlign: 'right' }}
                                className="text-align-right"
                            >
                                {item.page}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        );
        const tocTableHtmlString = ReactDOMServer.renderToString(TocTable);
        const root = parse(context.html);
        const pageElement = root.querySelector(`#tableOfContentsTable`);
        if (pageElement) pageElement.innerHTML = tocTableHtmlString;
        context.setHtml(root.innerHTML);
    }, [tocList]);

    return (
        <Drawer title="Table of Contents Page" placement="right" onClose={closeModal} visible={visible} width="25vw">
            <p>
                APA 7th Edition does not offer guidelines for creating a Table of Contents. The Table of Contents is
                built from the headings you add to your paper using our Text &amp; Heading Styles menu button.
            </p>
            <p>
                We create the Table of Contents by following the rules available in the APA 7th Edition Manual for other
                text sections.{' '}
            </p>
            <p>
                The Table of Contents is built from the headings you add to your paper using our Text &amp; Heading
                Styles menu button.
            </p>
            {context.html.match(/id="tableOfContentsPage"/gi)?.length ? (
                <>
                    <Button
                        danger
                        type="default"
                        block
                        onClick={removeTableOfContentsPageDrawerPage}
                        style={{ marginBottom: '1rem' }}
                    >
                        Remove Table of Contents Page
                    </Button>
                    <Button type="primary" block onClick={refreshTableOfContents} loading={loading}>
                        Refresh Table of Contents Page
                    </Button>
                </>
            ) : (
                <Button type="primary" block onClick={addTableOfContentsPageDrawerPage}>
                    Add Table of Contents Page
                </Button>
            )}
            {getPagePreview('abstractPage')}
            {getPagePreview('tableOfContentsPage')}
            {getPagePreview('contentPage')}
            {getPagePreview('referencePage')}
            {[
                'appendixPage-A',
                'appendixPage-B',
                'appendixPage-C',
                'appendixPage-D',
                'appendixPage-E',
                'appendixPage-F',
                'appendixPage-G',
                'appendixPage-H',
                'appendixPage-I',
                'appendixPage-J',
            ].map(pageId => getPagePreview(pageId))}
        </Drawer>
    );
};

export default TableOfContentsPageDrawer;
