import React, { useContext } from 'react';

import { Drawer, Typography, Divider, Button, message } from 'antd';
import { parse, Node } from 'node-html-parser';
import ReactDOMServer from 'react-dom/server';

import { GlobalContext } from '../context/GlobalContextProvider';
import ConclusionPage from './ConclusionPage';
import Page from './Page';

export const ConclusionPageDrawer = ({
    visible,
    setVisibility,
}: {
    visible: boolean;
    setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const context = useContext(GlobalContext);

    const closeModal = () => {
        setVisibility(false);
    };

    const removeConclusionPage = () => {
        const root = parse(context.html);
        const childNodes = root.childNodes[0].childNodes as (Node & {
            rawTagName?: string;
            classNames?: string[];
            id?: string;
        })[];
        // Replace conclusion page
        root.childNodes[0].childNodes = childNodes.filter(childNode => {
            if (
                childNode.rawTagName === 'div' &&
                childNode.classNames?.includes('page') &&
                childNode.id === 'conclusionPage'
            ) {
                return false;
            } else return true;
        });
        context.setHtml(root.innerHTML);
        message.success('Conclusion page has been removed');
    };

    const addConclusionPage = () => {
        const root = parse(context.html);
        const conclusionPage = ReactDOMServer.renderToString(
            <Page id="conclusionPage">
                <ConclusionPage />
            </Page>,
        );
        const conclusionPageNode = parse(conclusionPage);
        const parent = root.firstChild;
        const childNodes = parent.childNodes as (Node & {
            rawTagName?: string;
            classNames?: string[];
            id?: string;
        })[];
        // Add conclusion page after title page
        let contentPageNode = -1;
        childNodes.forEach((childNode, i) => {
            if (
                childNode.rawTagName === 'div' &&
                childNode.classNames?.includes('page') &&
                childNode.id === 'contentPage'
            ) {
                contentPageNode = i;
            }
        });
        if (contentPageNode > -1) childNodes.splice(contentPageNode + 1, 0, ...conclusionPageNode.childNodes);
        context.setHtml(root.innerHTML);
        message.success('Conclusion page has been added');
    };

    return (
        <Drawer title="Conclusion Page" placement="right" onClose={closeModal} visible={visible} width="25vw">
            {context.html.match(/id="conclusionPage"/gi)?.length ? (
                <Button danger type="default" block onClick={removeConclusionPage}>
                    Remove Conclusion Page
                </Button>
            ) : (
                <Button type="primary" block onClick={addConclusionPage}>
                    Add Conclusion Page
                </Button>
            )}
        </Drawer>
    );
};

export default ConclusionPageDrawer;
