import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
    ExportOutlined,
    FontSizeOutlined,
    SolutionOutlined,
    HomeOutlined,
    PictureOutlined,
    TableOutlined,
    FormOutlined,
    PaperClipOutlined,
    OrderedListOutlined,
    BookOutlined,
    UserOutlined,
    LogoutOutlined,
    SaveOutlined,
    BarsOutlined,
    ShrinkOutlined,
    LinkOutlined,
    RobotOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Drawer, Spin, Divider, Dropdown, Tag } from 'antd';
import { navigate } from 'gatsby';
import parse from 'node-html-parser';
import ReactDOMServer from 'react-dom/server';

import AbstractPage from '../components/AbstractPage';
import AbstractPageDrawer from '../components/AbstractPageDrawer';
import AddAppendixDrawer from '../components/AddAppendixDrawer';
import AddFigureDrawer from '../components/AddFigureDrawer';
import AddTableDrawer from '../components/AddTableDrawer';
import { AutoCheckDrawer } from '../components/AutoCheckDrawer';
import ConclusionPage from '../components/ConclusionPage';
import ConclusionPageDrawer from '../components/ConclusionPageDrawer';
import ContentPage from '../components/ContentPage';
import Editor from '../components/Editor';
import ExportModal from '../components/Export';
import Head from '../components/Head';
import Page from '../components/Page';
import ReferencePageDrawer from '../components/ReferencePageDrawer';
import ReferencesPage from '../components/ReferencesPage';
import SharePaperModal from '../components/SharePaperModal';
import TableOfContentsPageDrawer from '../components/TableOfContentsPageDrawer';
import TitlePage from '../components/TitlePage';
import TitlePageDrawer from '../components/TitlePageDrawer';
import { GlobalContext } from '../context/GlobalContextProvider';
import { useAuth, usePaper } from '../hooks';
import { handleError, handleSuccess } from '../utility';

const { Header, Sider, Content } = Layout;

export const EditorPage = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [showTitlePageDrawer, setShowTitlePageDrawer] = useState(false);
    const [showAbstractPageDrawer, setShowAbstractPageDrawer] = useState(false);
    const [showConclusionPageDrawer, setShowConclusionPageDrawer] = useState(false);
    const [showTableOfContentsPageDrawer, setShowTableOfContentsPageDrawer] = useState(false);
    const [showAddFigureDrawer, setShowAddFigureDrawer] = useState(false);
    const [showAddTableDrawer, setShowAddTableDrawer] = useState(false);
    const [showAddAppendixDrawer, setShowAddAppendixDrawer] = useState(false);
    const [showReferenceDrawer, setShowReferenceDrawer] = useState(false);
    const [exportModalVisible, setExportModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const { loggedInUser, logout, localLoginCheck } = useAuth();
    const { savePaper, paperLoading, loadPaper, activePaper, updateTitlePage } = usePaper();
    const context = useContext(GlobalContext);

    const onCollapse = (collapsed: boolean) => {
        setCollapsed(collapsed);
    };

    const showExportModal = () => {
        setExportModalVisible(true);
    };

    useEffect(() => {
        localLoginCheck();
    }, []);

    useEffect(() => {
        if (activePaper) {
            const { rawHtml, type } = activePaper;
            if (!rawHtml) {
                if (type === 'research') {
                    context.setHtml(
                        ReactDOMServer.renderToString(
                            <div className="mceNonEditable">
                                <Page id="titlePage">
                                    <TitlePage {...activePaper} />
                                </Page>
                                <Page id="abstractPage">
                                    <AbstractPage />
                                </Page>
                                <Page id="contentPage">
                                    <ContentPage title={activePaper.title} />
                                </Page>
                                <Page id="conclusionPage">
                                    <ConclusionPage />
                                </Page>
                                <Page id="referencesPage">
                                    <ReferencesPage />
                                </Page>
                            </div>,
                        ),
                    );
                } else if (type === 'assignment') {
                    context.setHtml(
                        ReactDOMServer.renderToString(
                            <div className="mceNonEditable">
                                <Page id="titlePage">
                                    <TitlePage {...activePaper} />
                                </Page>
                                <Page id="contentPage">
                                    <ContentPage title={activePaper.title} />
                                </Page>
                                <Page id="referencesPage">
                                    <ReferencesPage />
                                </Page>
                            </div>,
                        ),
                    );
                } else if (type === 'discussion') {
                    context.setHtml(
                        ReactDOMServer.renderToString(
                            <div className="mceNonEditable">
                                <Page id="contentPage">
                                    <ContentPage title={activePaper.title} integrateReferences={true} />
                                </Page>
                            </div>,
                        ),
                    );
                }
            } else {
                context.setHtml(() => {
                    const root = parse(rawHtml);
                    const spans = root.querySelectorAll('span');
                    spans.forEach(span => {
                        const className = span.classNames;
                        if (className === 'highlight_invalid_between' || className === 'highlight_invalid_start') {
                            span.replaceWith(span.innerText);
                        }
                    });
                    return root.toString();
                });
            }
        }
    }, [activePaper]);

    useEffect(() => {
        context.editorLoaded && context.activePaper?._id && loadPaper();
    }, [context.activePaper?._id, context.editorLoaded]);

    useEffect(() => {
        const periodicSaver = setInterval(() => {
            savePaper();
        }, 60000);

        return () => {
            clearInterval(periodicSaver);
        };
    }, [context.html, context.titlePage]);

    return (
        <Layout style={{ height: '100vh' }}>
            <Head title="Editor" />
            <Spin spinning={loading}>
                <Header
                    style={{
                        padding: '0 0',
                        paddingRight: '1.5rem',
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        background: '#1f1e37',
                        position: 'sticky',
                        top: 0,
                        width: '100%',
                        left: 0,
                        right: 0,
                        zIndex: 1000,
                    }}
                >
                    <Menu theme="dark" mode="horizontal" style={{ background: '#1f1e37', flexGrow: 1 }}>
                        <Menu.Item disabled key="logo">
                            <div
                                style={{
                                    height: '64px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '1.2rem',
                                }}
                            >
                                {collapsed ? 'APA' : 'AutoAPA'}
                            </div>
                        </Menu.Item>
                        <Menu.Item key="home" icon={<HomeOutlined />} onClick={() => navigate('/')}>
                            Home
                        </Menu.Item>
                        <Menu.Item key="export" icon={<ExportOutlined />} onClick={showExportModal}>
                            Export
                        </Menu.Item>
                        <Menu.Item
                            key="save"
                            icon={paperLoading ? <SaveOutlined spin={true} /> : <SaveOutlined />}
                            onClick={() => {
                                savePaper();
                                handleSuccess('Paper has been saved');
                            }}
                        >
                            Save
                        </Menu.Item>
                        <Menu.Item
                            key="share"
                            icon={<LinkOutlined />}
                            onClick={async () => {
                                await savePaper();
                                context.setSharePaper(context.activePaper);
                            }}
                        >
                            Share
                        </Menu.Item>
                    </Menu>
                    <Dropdown
                        overlay={
                            <>
                                <Menu>
                                    <Menu.Item icon={<UserOutlined />}>
                                        <>
                                            {loggedInUser?.firstName || ''} {loggedInUser?.lastName || ''}{' '}
                                            {loggedInUser?.roles &&
                                                loggedInUser?.roles.length &&
                                                loggedInUser?.roles[0] === 'admin' && <Tag color="red">Admin</Tag>}
                                            {loggedInUser?.roles &&
                                                loggedInUser?.roles.length &&
                                                loggedInUser?.roles[0] === 'student' && <Tag color="blue">Student</Tag>}
                                        </>
                                    </Menu.Item>
                                    <Menu.Item onClick={logout} icon={<LogoutOutlined />}>
                                        Logout
                                    </Menu.Item>
                                </Menu>
                            </>
                        }
                    >
                        <UserOutlined style={{ color: 'white' }} />
                    </Dropdown>
                </Header>
                <Layout>
                    <Layout>
                        <Content>
                            <Editor setGlobalLoading={setLoading} />
                        </Content>
                    </Layout>
                    <Sider
                        collapsible
                        collapsed={collapsed}
                        onCollapse={onCollapse}
                        theme="light"
                        reverseArrow={true}
                        collapsedWidth="50px"
                    >
                        <Menu theme="light" mode="inline" selectable={false}>
                            <Menu.Item
                                key="title-page"
                                icon={<FontSizeOutlined />}
                                onClick={() => setShowTitlePageDrawer(true)}
                            >
                                Title Page
                            </Menu.Item>
                            <Menu.Item
                                key="abstract-page"
                                icon={<SolutionOutlined />}
                                onClick={() => setShowAbstractPageDrawer(true)}
                            >
                                Abstract
                            </Menu.Item>
                            {activePaper?.type !== 'discussion' && activePaper?.type !== 'assignment' && (
                                <Menu.Item
                                    key="tableOfContents-page"
                                    icon={<OrderedListOutlined />}
                                    onClick={() => setShowTableOfContentsPageDrawer(true)}
                                >
                                    Table of Contents
                                </Menu.Item>
                            )}
                            {activePaper?.type !== 'discussion' && activePaper?.type !== 'assignment' && (
                                <Menu.Item
                                    key="conclusion-page"
                                    icon={<ShrinkOutlined />}
                                    onClick={() => setShowConclusionPageDrawer(true)}
                                >
                                    Conclusion
                                </Menu.Item>
                            )}
                            <Menu.Item
                                key="references"
                                icon={<BookOutlined />}
                                onClick={() => setShowReferenceDrawer(true)}
                            >
                                References
                            </Menu.Item>

                            {activePaper?.type !== 'discussion' && activePaper?.type !== 'assignment' && (
                                <Menu.Item
                                    key="appendix-page"
                                    icon={<PaperClipOutlined />}
                                    onClick={() => setShowAddAppendixDrawer(true)}
                                >
                                    Appendix
                                </Menu.Item>
                            )}
                            <Menu.Item key="divider">
                                <Divider />
                            </Menu.Item>
                            {activePaper?.type !== 'discussion' && activePaper?.type !== 'assignment' && (
                                <Menu.Item
                                    key="add-figure"
                                    icon={<PictureOutlined />}
                                    onClick={() => setShowAddFigureDrawer(true)}
                                >
                                    Add Figure
                                </Menu.Item>
                            )}
                            {activePaper?.type !== 'discussion' && activePaper?.type !== 'assignment' && (
                                <Menu.Item
                                    key="add-table"
                                    icon={<TableOutlined />}
                                    onClick={() => {
                                        const parent = context?.editor?.current?.selection?.getNode().parentElement;
                                        const grandparent =
                                            context?.editor?.current?.selection?.getNode().parentElement?.parentElement;
                                        if (
                                            parent?.tagName?.toLowerCase() === 'td' ||
                                            grandparent?.tagName?.toLowerCase() === 'td'
                                        ) {
                                            handleError({ message: 'Cannot add nested tables' });
                                        } else {
                                            setShowAddTableDrawer(true);
                                        }
                                    }}
                                >
                                    Add Table
                                </Menu.Item>
                            )}
                            <Menu.Item
                                icon={<RobotOutlined />}
                                onClick={() => context.setShowReferenceCheckDrawer(true)}
                            >
                                System Checks
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <TitlePageDrawer visible={showTitlePageDrawer} setVisibility={setShowTitlePageDrawer} />
                    <AbstractPageDrawer visible={showAbstractPageDrawer} setVisibility={setShowAbstractPageDrawer} />
                    <ConclusionPageDrawer
                        visible={showConclusionPageDrawer}
                        setVisibility={setShowConclusionPageDrawer}
                    />
                    <TableOfContentsPageDrawer
                        visible={showTableOfContentsPageDrawer}
                        setVisibility={setShowTableOfContentsPageDrawer}
                    />
                    <AddFigureDrawer visible={showAddFigureDrawer} setVisibility={setShowAddFigureDrawer} />
                    <AddTableDrawer visible={showAddTableDrawer} setVisibility={setShowAddTableDrawer} />
                    <AddAppendixDrawer visible={showAddAppendixDrawer} setVisibility={setShowAddAppendixDrawer} />
                    <ReferencePageDrawer visible={showReferenceDrawer} setVisibility={setShowReferenceDrawer} />
                </Layout>
                <ExportModal visible={exportModalVisible} setVisibility={setExportModalVisible} />
                <SharePaperModal />
                <AutoCheckDrawer />
            </Spin>
        </Layout>
    );
};

export default EditorPage;
